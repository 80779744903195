@font-face {
  font-family: 'Beethoven';
  src: url('./fonts/Beethoven.ttf') format("truetype");
  font-weight: 100 900;
}

@font-face {
  font-family: 'Xtreem';
  src: url('./fonts/XtreemFat.ttf') format('truetype');
  font-weight: 500 900;
}

@font-face {
  font-family: '851 Chikara';
  src: url('./fonts/851CHIKARA-DZUYOKU_kanaA_004.ttf') format('truetype'),
       url('./fonts/851CHIKARA-DZUYOKU_kanaB_004.ttf') format('truetype');
  font-weight: 100 900;
}

@font-face {
  font-family: 'GN Kill Gothic U';
  src: url('./fonts/GN-KillGothic-U-KanaNA.ttf') format('truetype'),
       url('./fonts/GN-KillGothic-U-KanaNB.ttf') format('truetype'),
       url('./fonts/GN-KillGothic-U-KanaO.ttf') format('truetype');
  font-weight: 100 900;
}

/* Yellow: #E0F4BE */

* {
  box-sizing: border-box;
  margin: 0;
}

* + * {
  margin-top: 1.5rem;
}

html,
body {
  min-height: 100%;
}

body {
  background: #D9E3DE linear-gradient(to top, #EE7CE3, #D9E3DE);
  font-family: sans-serif;
  text-transform: uppercase;
  line-height: 1.5;
  margin: 0;
}

a {
  color: #D41A1A;
}

h1 {
  font-family: 'Beethoven', sans-serif;
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  line-height: 1;
}

h2 {
  text-align: center;
}

h1 + p,
h2 + p {
  margin-top: 0.375rem;
}

label {
  display: block;
  font-weight: bold;
  letter-spacing: 0.0625ch;
}

input {
  font: inherit;
  padding: 0.75rem;
  width: 100%;
  border: 0.125em solid #000;
  color: #D41A1A;
}

input:focus {
  border-color: currentColor;
}

label + input {
  margin-top: 0.1875rem;
}

summary {
  cursor: default;
}

button {
  font: inherit;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  background: #D41A1A;
  color: white;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.0625ch;
  border: 0;
}

* + button {
  margin-top: 3rem;
}

title-text-frame:defined {
  transition: opacity 250ms ease-out;
}

title-text-frame:defined[hidden] {
  opacity: 0;
}

title-text-form {
  background: #B7F0C2;
  border: 0.75rem solid #D969E7;
  position: relative;
  padding: 1.5rem;
}

aria-status {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  margin: 0 !important;
}

@supports (clip-path: polygon(0% 0%, 100% 0%, 100% 100%)) or (-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%)) {
  title-text-form {
    background: none;
    border: none;
    padding: calc(3rem + 5%);
  }

  title-text-form::before {
    background: #D969E7;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-clip-path: polygon(4.01% 33.72%, 1.35% 15.38%, 51.08% 6.18%, 90.63% 11.58%, 88.67% 35.07%, 92.34% 58.26%, 86.53% 96.39%, 38.6% 92.6%, 22.23% 99.16%, 7.31% 85.06%);
    clip-path: polygon(4.01% 33.72%, 1.35% 15.38%, 51.08% 6.18%, 90.63% 11.58%, 88.67% 35.07%, 92.34% 58.26%, 86.53% 96.39%, 38.6% 92.6%, 22.23% 99.16%, 7.31% 85.06%);
  }

  title-text-form::after {
    background: #B7F0C2;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-clip-path: polygon(10.36% 5.68%, 97.23% 16.96%, 71.94% 98.42%, 3.29% 80.92%);
    clip-path: polygon(10.36% 5.68%, 97.23% 16.96%, 71.94% 98.42%, 3.29% 80.92%);
  }
}

title-text-form > * {
  position: relative;
  z-index: 1;
}

.wrapper {
  margin: 0 auto;
  padding-top: 3rem;
  max-width: 100rem;
  padding-bottom: 3rem;
}

.wrapper > header {
  text-align: center;
}

.wrapper > footer {
  font-weight: bold;
  text-align: center;
  background: white;
  padding: 3rem;
  -webkit-clip-path: polygon(calc(50% - 12em) 0%, calc(50% + 12em) 0%, 50% 100%);
  clip-path: polygon(calc(50% - 12em) 0%, calc(50% + 12em) 0%, 50% 100%);
}

@supports (clip-path: polygon(0% 0%, 0% 100%, 100% 100%)) or (-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%)) {
  .wrapper > footer {
    padding: 3rem 3rem 12rem;
  }
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-bottom: -1.5rem;
  align-items: center;
}

.main-content > * {
  flex: 3 1 calc((80rem - 100%) * 9999);
  margin: 0;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  min-width: 0;
}

.main-content > * + * {
  flex-grow: 7;
}

.main-content ~ * {
  margin-top: 3rem;
}

.line-input {
  text-transform: uppercase;
}

.center {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.center > * {
  margin-left: auto;
  margin-right: auto;
  max-width: 38rem;
}

.box {
  background: white;
  padding: 1.5rem;
  -webkit-clip-path: polygon(-3.3% 10.63%, 94.88% -12.31%, 102.73% 90.76%, 2.35% 108.70%);
  clip-path: polygon(-3.3% 10.63%, 94.88% -12.31%, 102.73% 90.76%, 2.35% 108.70%);
  filter: drop-shadow(0, 0.25em, 0, 0.25em black);
}

.fallback-message {
  padding: 1.5rem;
  background: black;
  color: white;
}

:focus {
  outline: 0.25rem solid;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
